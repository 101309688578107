/*
    Demands
*/

// Send to design office
function adminSendDemandToDesignOffice(id) {
    adminSendToDesignOffice([id]);
}

function adminSendDemandsToDesignOffice() {
    var ids = getSelectedItems();
    adminSendToDesignOffice(ids);
}

function adminSendToDesignOffice(ids) {
    var title = "Envoyer au bureau d'étude ?";
    if(ids.length > 1) {
        title = "Envoyer les " + ids.length + " demandes au bureau d'étude ?";
    }
    actionConfirmation(title, null, ids, '/demands/send_demands_to_design_office', false)
}

// Send to cloud builder exam
function adminSendDemandToCloudBuilderExam(id) {
    adminSendToCloudBuilderExam([id]);
}

function adminSendDemandsToCloudBuilderExam() {
    var ids = getSelectedItems();
    adminSendToCloudBuilderExam(ids);
}

function adminSendToCloudBuilderExam(ids) {
    var title = "Passer en examen cloud builder ?";
    if(ids.length > 1) {
        title = "Envoyer les " + ids.length + " demandes en examen cloud builder ?";
    }
    actionConfirmation(title, null, ids, '/demands/send_demands_to_cloud_builder_exam', false)
}

// Return demands to design office
function adminReturnDemandToDesignOffice(id) {
    adminReturnToDesignOffice([id]);
}

function adminReturnDemandsToDesignOffice() {
    var ids = getSelectedItems();
    adminReturnToDesignOffice(ids);
}

function adminReturnToDesignOffice(ids) {
    var title = "Envoyer au bureau d'étude ?";
    if(ids.length > 1) {
        title = "Envoyer les " + ids.length + " demandes au bureau d'étude ?";
    }
    actionConfirmation(title, null, ids, '/demands/return_demands_to_design_office', false)
}

// Edit chrono
function adminEditChronoForDemand(id) {
    adminEditChrono([id]);
}
function adminEditChronoForDemands() {
    var ids = getSelectedItems();
    adminEditChrono(ids);
}

function adminEditChrono(ids) {
    $.post('/demands/edit_chrono_for_demands', {
        demands_id : ids,
    }, function (data) {
        openModal(data);
    });
}

// Send to client exam
function adminSendDemandsToClientExam() {
    var ids = [];
    var myForm = $('#myModal').find('form').serializeArray();
    for (var i = 0, len = myForm.length; i < len; i++) {
        if (myForm[i].name == "demands_id[]") {
            ids.push(myForm[i].value);
        }
    }

    swal({
        title: "Envoyer au client pour examen ?",
        type: "warning",
        animation: false,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Envoyer",
        cancelButtonText: "Annuler",
        closeOnConfirm: false
    }, function () {
        $.post('demands/send_demands_to_client_exam', $('#myModal').find('form').serialize(), function (data) {
            for (var i = 0; i < ids.length; i++) {
                updateIndexLine(ids[i], false);
            }
            closeModal();
            swal("Demande envoyée", "", "success");
        }).fail(function (data) {
            swal.close();
            openModal(data.responseText);
        });
    });
}

/*
    Works
*/

// Plan intervention
function planInterventionForDemand(id) {
    planInterventions([id]);
}

function planInterventionForDemands() {
    var ids = getSelectedItems();
    planInterventions(ids);
}

function planInterventions(ids) {
    $.post('/demands/plan_intervention_for_demands', {
        demands_id : ids,
        filter_work_status: filter_work_status
    }, function (data) {
        openModal(data);
    });
}

function validateInterventionPlanningForDemands() {
    var ids = [];
    var myForm = $('#myModal').find('form').serializeArray();
    for (var i = 0, len = myForm.length; i < len; i++) {
        if (myForm[i].name == "demands_id[]") {
            ids.push(myForm[i].value);
        }
    }
    var title = "Confirmer le planning d'intervention ?";
    if (ids.length > 1) {
        title = "Confirmer le planning d'intervention pour les " +ids.length+" travaux ?"
    }
    swal({
        title: title,
        text: "Les travaux débuteront automatiquement à la date indiquée.",
        type: "warning",
        animation: false,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Envoyer",
        cancelButtonText: "Annuler",
        closeOnConfirm: false
    }, function () {
        $.post('/demands/validate_intervention_planning_for_demands', $('#myModal').find('form').serialize(), function (data) {
            for (var i = 0; i < ids.length; i++) {
                updateIndexLine(ids[i], true);
            }
            closeModal();
            swal("Travaux planifiés", "", "success");
        }).fail(function (data) {
            swal.close();
            openModal(data.responseText);
        });

    });
}

// Validate connection
function validateConnectionForDemand(id) {
    validateConnection([id]);
}

function validateConnectionForDemands() {
    var ids = getSelectedItems();
    validateConnection(ids);
}

function validateConnection(ids) {
    var title = "Confirmer le raccordement ?";
    if(ids.length > 1) {
        title = "Confirmer le raccordement pour les " + ids.length + " demandes ?";
    }
    actionConfirmation(title, "Cette action ne peut être annulée.", ids, '/demands/validate_connection_for_demands', true)
}

// Archivate
function archivateDemand(id) {
    archivate([id]);
}

function archivateDemands() {
    var ids = getSelectedItems();
    archivate(ids);
}

function archivate(ids) {
    var title = "Archiver l'intervention ?";
    var subtitle = "L'intervention ne sera plus visible dans l'écran des travaux mais toujours disponible dans le détail du site";
    if(ids.length > 1) {
        title = "Archiver les " + ids.length + " interventions ?";
        subtitle = "Les interventions ne seront plus visibles dans l'écran des travaux mais toujours disponibles dans le détail du site";
    }
    actionConfirmation(title, subtitle, ids, '/demands/archivate_demands', true)
}