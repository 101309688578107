// Send to design office
function clientSendDemandToDesignOffice(id) {
    clientSendToDesignOffice([id]);
}

function clientSendDemandsToDesignOffice() {
    var ids = getSelectedItems();
    clientSendToDesignOffice(ids);
}

function clientSendToDesignOffice(ids) {
    var title = "Envoyer à Axians ?";
    if(ids.length > 1) {
        title = "Envoyer les " + ids.length + " à Axians ?";
    }
    actionConfirmation(title, "Vous ne pourrez plus éditer cette demande.", ids, '/demands/send_demands_to_design_office', false)
}

// Edit works start date
function clientEditDemandWorksStartDate(id) {
    clientEditWorksStartDate([id]);
}

function clientEditDemandsWorksStartDate() {
    var ids = getSelectedItems();
    clientEditWorksStartDate(ids);
}

function clientEditWorksStartDate(ids) {
    $.post('/demands/edit_demands_works_start_date', {
        demands_id : ids
    }, function (data) {
        openModal(data);
    });
}

// Send technical go
function clientSendDemandsToTechnicalGo() {
    var ids = [];
    var myForm = $('#myModal').find('form').serializeArray();
    for (var i = 0, len = myForm.length; i < len; i++) {
        if (myForm[i].name == "demands_id[]") {
            ids.push(myForm[i].value);
        }
    }

    swal({
        title: "Valider le go technique des demandes ?",
        type: "warning",
        animation: false,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Valider",
        cancelButtonText: "Annuler",
        closeOnConfirm: false
    }, function () {
        $.post('/demands/send_demands_to_technical_go', $('#myModal').find('form').serialize(), function (data) {
            closeModal();
            for (var i = 0; i < ids.length; i++) {
                updateIndexLine(ids[i], false);
            }
            swal("Go technique validé", "", "success");
        }).fail(function (data) {
            swal.close();
            openModal(data.responseText);
        });
    });
}

// Edit commands number
function clientEditCommandsNumberForDemand(id) {
    clientEditCommandsNumber([id]);
}

function clientEditCommandsNumberForDemands() {
    var ids = getSelectedItems();
    clientEditCommandsNumber(ids);
}

function clientEditCommandsNumber(ids) {
    $.post('/demands/edit_demands_command_number', {
        demands_id : ids
    }, function (data) {
        openModal(data);
    });
}

// Send financial go
function clientSendDemandsToFinancialGo() {
    var ids = [];
    var myForm = $('#myModal').find('form').serializeArray();
    for (var i = 0, len = myForm.length; i < len; i++) {
        if (myForm[i].name == "demands_id[]") {
            ids.push(myForm[i].value);
        }
    }
    swal({
        title: "Valider le go financier des demandes ?",
        type: "warning",
        animation: false,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Valider",
        cancelButtonText: "Annuler",
        closeOnConfirm: false
    }, function () {
        $.post('/demands/send_demands_to_financial_go', $('#myModal').find('form').serialize(), function (data) {
            closeModal();
            for (var i = 0; i < ids.length; i++) {
                updateIndexLine(ids[i], false);
            }
            swal("Go financier validé", "", "success");
        }).fail(function (data) {
            swal.close();
            openModal(data.responseText);
        });

    });
}