// Validate connection
function validateConnectionForDemand(id) {
    validateConnection([id]);
}

function validateConnectionForDemands() {
    var ids = getSelectedItems();
    validateConnection(ids);
}

function validateConnection(ids) {
    var title = "Confirmer le raccordement ?";
    if(ids.length > 1) {
        title = "Confirmer le raccordement pour les " + ids.length + " demandes ?";
    }
    actionConfirmation(title, "Cette action ne peut être annulée.", ids, '/demands/validate_connection_for_demands', true)
}