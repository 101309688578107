var mass_checkbox_entity;
var filter_work_status;

function initMassAction(entity, filterWorkStatus) {
    $('#mass-action-button').hide();
    mass_checkbox_entity = entity;
    filter_work_status = filterWorkStatus;
    initCheckBox();
}

function initCheckBox() {
    $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green'
    });
    $('input[name$="select_all_'+mass_checkbox_entity+'s"]').on('ifChecked', function () {
        $('input[name="selected_'+mass_checkbox_entity+'s"]').iCheck('check');
    });
    $('input[name$="select_all_'+mass_checkbox_entity+'s"]').on('ifUnchecked', function () {
        $('input[name="selected_'+mass_checkbox_entity+'s"]').iCheck('uncheck');
    });
    $('input[name="selected_'+mass_checkbox_entity+'s"]').on('ifChanged', function(event) {
        updateMassActionButtonVisibility();
    });
}

function updateMassActionButtonVisibility() {
    var selected_lenght = $('input[name="selected_'+mass_checkbox_entity+'s"]:checked').length;
    if (selected_lenght > 0) {
        $('#mass-action-button').show();
    } else {
        $('#mass-action-button').hide();
    }
}

function actionConfirmation(title, text, ids, url, isForWork) {
    swal({
        title: title,
        text: text,
        type: "warning",
        animation: false,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Valider",
        cancelButtonText: "Annuler",
        closeOnConfirm: false
    }, function () {
        callAPI(url, ids, isForWork);
    });
}

function callAPI(url, ids, isForWork) {
    $.post(url, {
        demands_id : ids
    }, function (data) {
        for (var i = 0; i < ids.length; i++) {
            updateIndexLine(ids[i], isForWork);
        }
        swal("Demandes envoyées", "", "success");
    }).fail(function (data) {
        for (var i = 0; i < ids.length; i++) {
            var id = parseInt(ids[i]);
            if (data.responseJSON.error_ids.indexOf(id) != -1) {
                updateIndexLine(ids[i], isForWork);
            }
        }
        swal("Une erreur est survenue", "", "error");
    });
}

function getSelectedItems() {
    var ids = [];
    $('input[name="selected_'+mass_checkbox_entity+'s"]:checked').each(function() {
        ids.push($(this).val());
    });
    return ids;
}

function updateIndexLine(demandId, isForWork) {
    var url = '/demands/'+demandId+'/demand_index_line';
    if (isForWork) {
        url = '/demands/'+demandId+'/work_index_line';
    }
    $.post(url, {
        work_status : filter_work_status
    }, function (data) {
        $('#' + mass_checkbox_entity + demandId).replaceWith(data);
        initCheckBox();
    }).fail(function (data) {
        $('#' + mass_checkbox_entity + demandId).replaceWith(data);
    });
}